import { IconArrowRight } from "@tabler/icons-react";
import React from "react";

const Banner = ({bredcrumb}) => {
  return (
    <div className="banner mb-3">
      <div className="container py-4">
        <div className="line"></div>
        <h1 className="text-center text-light">
          Home <IconArrowRight width={30} height={30} /><span className="text-white"> {bredcrumb}</span>
        </h1>
      </div>
    </div>
  );
};

export default Banner;
