import { IconChevronDown, IconMailFilled, IconMenu, IconPhoneFilled, IconX } from "@tabler/icons-react";
import React from "react";
import { useState } from 'react';
import {Link}  from 'react-router-dom';
import Logo from "../../assets/images/logo.png";

const Navbar = () => {
  const navItems = [
    {
      name: "Home",
      route: "",
    },
    {
      name: "About",
      route: "about-us",
    },
    {
      name: "Our Service",
      route: "",
      subMenu: [
        {
          name: "Ocean Freight",
          route: "ocean-Freight",
        },
        {
          name: "Air Freight",
          route: "air-Freight",
        },
        {
          name: "Custom Clearance",
          route: "custom-clearance",
        },
        {
          name: "Inland Transportation",
          route: "inland-transportation",
        },
        {
          name: "Chartering",
          route: "Chartering",
        },
        {
          name: "Wahrehousing and Distribution",
          route: "wharehousing-and-distribution",
        },
        {
          name: "Global Freight Forwarding",
          route: "global-Freight-forwarding",
        },
        {
          name: "Export/Import Consultancy",
          route: "export-import-consultancy",
        },
      ],
    },
    {
      name: "Genral Information",
      route: "",
      subMenu: [
        {
          name: "Document For Import Clearance",
          route: "document-for-import-clearance",
        },
        {
          name: "Document For Export Clearance",
          route: "document-for-export-clearance",
        },
        {
          name: "Document For ATA Carnet",
          route: "document-for-ata-carnet",
        },
        {
          name: "Mesurement Criteria",
          route: "mesurement-criteria",
        },
        {
          name: "Weight Criteria",
          route: "weight-criteria",
        },
      ],
    },
    {
      name: "Contact",
      route: "contact-us",
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLinkClick = () => {
    if (isMenuOpen) {
      closeMenu();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct WhatsApp link with form data
    const whatsappLink = `https://wa.me/+919935990372`;

    window.open(whatsappLink, "_blank");
  };
  
  return (
    <>
      <div className="topbar">
        <div className="container d-flex justify-content-between py-1">
          <small className="d-flex align-items-center gap-2"><IconMailFilled width={16} /> sales@easygloballogistics.com</small>
          <small className="d-flex align-items-center gap-2"><IconPhoneFilled width={16} />  9628249799 </small>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo} width="180px" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
            >
              {isMenuOpen ? <IconX /> : <IconMenu />}
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''} justify-content-end`}
            id="navbarNav"
          >
            <ul className="navbar-nav gap-3 align-items-center">
              {navItems.map((item) =>
                item.subMenu ? (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {item.name} <IconChevronDown width={16} />
                    </a>
                    <ul className="dropdown-menu">
                      {item.subMenu.map((submenu)=>(
                        <li>
                      
                        <Link className="dropdown-item" onClick={handleLinkClick} to={submenu.route}>
                           {submenu.name}
                         </Link>
                      </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link" onClick={handleLinkClick} to={item.route}>
                      {item.name}
                    </Link>
                  </li>
                )
              )}
               <li className="nav-item">
                    <button className="primary-button" onClick={handleSubmit}>Get Quote</button>
                </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
