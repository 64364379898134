import React from "react";
import { Link } from "react-router-dom";

const Infos = ({ infos }) => {
  return (
    <div className="my-5 container service">
      <div className="row gap-3">
        <div className="col-md-3 gap-1 text-center d-flex flex-column">
          <Link className="primary-button" to="/document-for-import-clearance">
            Document For Import Clearance
          </Link>
          <Link className="primary-button" to="/document-for-export-clearance">
            Document For Export Clearance
          </Link>
          <Link className="primary-button" to="/document-for-ata-carnet">
            Document For ATA Carnet
          </Link>
          <Link className="primary-button" to="/mesurement-criteria">
            Mesurement Criteria
          </Link>
          <Link className="primary-button" to="/weight-criteria">
            Weight Criteria
          </Link>
        </div>
        <div className="col-md-8">
          <h2>
            {infos.name} <span>{infos.span}</span>
          </h2>
          {infos.paragraphs.map((paragraph, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Infos;
