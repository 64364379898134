import React from 'react'
import Banner from './includes/Banner'
import Infos from './includes/Infos';

const DocumentForImportClearance = () => {
    document.title = "Document For Import Clearance | Easy Global";
    const infos = {
      name: "Document For",
      span: "Import Clearance",
      paragraphs: [
        "<ul><li>Original Invoice + Packing list duly signed & stamped by the importer.</li><li>Original Bill of Lading and/or Airway Bill ( as the case may be) duly Endorsed by the importer on the back.</li><li>Certificate of insurance(in case of CIF shipment).</li><li>Certificate of origin.</li><li>Gatt Declaration.</li><li>Import licence (in case of restricted item or imports under any licensed scheme such as EPCG and Advance Authorisation etc)</li><li>Illustrative write-up or literature / catalogue in case of import of machinery, Electrical/ mechanical fittings and product composition in case of chemicals and similar other items.</li></ul>        "
      ],
    };
  return (
    <>
    <Banner bredcrumb="Document For Import Clearance" />
    <Infos infos={infos} />
    </>
  )
}

export default DocumentForImportClearance