import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const CustomClearance = () => {
  document.title = "Custom Clearance | Easy Global";
  const service = {
    name: "Custom",
    span: "Clearance",
    paragraphs: [
      "<b>Easy Global</b> is a Government licensed custom clearing Agency and are basically engaged in the field of clearance of import and export cargos of all modes (Sea / Air and Land routes) through the various custom stations in the northern India.",
      "Our team of professionals are well experienced in local customs procedures and DGFT matters and as such all the procedural formalities are properly taken care of and adhered to for the convenience of our customers. We have our own offices at All custom stations in NCR besides having own networks at other major northern Indian Dry ports and all export/import clearance activities are personally supervised & manned by our own dedicated staff.",
      "Our company is fully committed to Total Quality Management (TQM) services and our team is individually dedicated to expedite execution of various assignments with decentralization of authority at various levels."
    ],
  };
  return (
    <>
      <Banner bredcrumb="Custom Clearance" />
      <Services service={service} />
    </>
  );
};

export default CustomClearance;
