import React from 'react'
import Banner from './includes/Banner'
import Infos from './includes/Infos';

const Mesurement = () => {
    document.title = "Mesurement Criteria | Easy Global";
    const infos = {
      name: "Mesurement",
      span: "Criteria",
      paragraphs: [
        "To find cbm (cubic meter) of the shipment ( by sea), if the carton/package dimensions are in cms & inches",
        "For example,",
        "<b>- Dimensions in centimeters</b>",
        "L + Length, B=Breadth, H=Height (LBH)",
        "L x B x H x No. of cartons / packages",
        "_______________________________",
        "1000000",
        "<b>- Dimensions are in inches</b>",
        "L x B x H x No. of cartons / packages",
        "___________________________________",
        "60000"
      ],
    };
  return (
    <>
    <Banner bredcrumb="Mesurement Criteria" />
    <Infos infos={infos} />
    </>
  )
}

export default Mesurement