import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const Chartering = () => {
  document.title = "Chartering | Easy Global";
  const service = {
    name: "",
    span: "Chartering",
    paragraphs: [
      "<b>Heavy lifts</b> : we do specialize in handling project cargo through our technically enabled project cargo Team - We do have experience of handling chartering for many corporates in India.",
      "We provide logistics support that includes heavy-lift and out-of-gauge cargo moves for capital projects, oil and gas projects, and marine services. As project managers or expert consultants, we handle logistics planning at all stages of the project cycle from site selection to Pre-FEED, FEED and final execution. We develop route plans, cost modeling and project-support tools vital to customers with complex movements that require careful staging, sequencing and coordination. Relying on our global network of 500 associates in over 100 countries, our professionals offer import and export compliance and packing, customs clearance, warehousing and storage, along with comprehensive marine service management required to deliver your cargo on time anywhere in the world."
    ],
  };
  return (
    <>
      <Banner bredcrumb="Chartering" />
      <Services service={service} />
    </>
  );
};

export default Chartering;
