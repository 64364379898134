import React from 'react'
import Banner from './includes/Banner'
import Infos from './includes/Infos';

const Weight = () => {
    document.title = "Weight Criteria | Easy Global";
    const infos = {
      name: "Weight",
      span: "Criteria",
      paragraphs: [
        "To find cbm (cubic meter) of the shipment ( by sea), if the carton/package dimensions are in cms & inches",
        "For example,",
        "<b>- Dimensions in centimeters</b>",
        "L + Length, B=Breadth, H=Height (LBH)",
        "L x B x H x No. of cartons / packages",
        "_______________________________",
        "6000",
        "<b>- Dimensions are in inches</b>",
        "L x B x H x No. of cartons / packages",
        "___________________________________",
        "366"
      ],
    };
  return (
    <>
    <Banner bredcrumb="Weight Criteria" />
    <Infos infos={infos} />
    </>
  )
}

export default Weight