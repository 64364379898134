import React, {useState} from 'react'
import Banner from "./includes/Banner"
import contact from "../../assets/images/contact.svg"


const Contact = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct WhatsApp link with form data
    const whatsappLink = `https://wa.me/+919935990372?text=Name: ${formData.name}%0AEmail: ${formData.email}%0APhone: ${formData.phone}%0AMessage: ${formData.message}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <>
    <Banner bredcrumb="Contact Us" />
    <div className="contact">
        <div className="container py-5">
            <h1 className="text-center">Contact <span>Us</span></h1>
            <div className="row py-5">
              <div className="col-md-6 align-self-center">
                 <img src={contact} alt="" className="w-100"/>
              </div>
              <div className="col-md-6">
              <form action="" onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label htmlFor="">
                    Name <span>*</span>
                  </label>
                  <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} />
                </div>
                <div className="mb-2">
                  <label htmlFor="">
                    Email <span>*</span>
                  </label>
                  <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control" />
                </div>
                <div className="mb-2">
                  <label htmlFor="">
                    Phone <span>*</span>
                  </label>
                  <input type="tel" name="phone" value={formData.phone} onChange={handleChange} className="form-control" />
                </div>
                <div className="mb-2">
                  <label htmlFor="">
                    Message <span>*</span>
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control"
                    id=""
                    cols="30"
                    rows="5"
                  ></textarea>
                </div>
                <div className="mb-2">
                  <input
                    type="submit"
                    className="form-control primary-button"
                  />
                </div>
              </form>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Contact