import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const WharehousingAndDistribution = () => {
    document.title = "Wharehousing And Distribution | Easy Global";
    const service = {
      name: "Wharehousing And",
      span: "Distribution",
      paragraphs: [
        "Warehousing & Distribution Services As an experienced logistics company, Trination logistics pvt ltd. understands all the issues and challenges of the nowadays business environment. Being aware of importance of warehousing and distribution strategy we provide integrated supply chain solutions to our customers that reduce their inventory levels, improve efficiency and cut down on logistics costs.      ",
        "Whatever industry sector you operate in and wherever you need to store your inventory, Trination logistics pvt ltd. through its worldwide network of agents can organize warehouses and distribution anywhere in the world. We always ensure that you can fulfill your commitments given to your customers and your business can grow globally.",
        "<b>Our Warehousing & Distribution services include:</b>",
        "<ul><li>Inventory management and control</li><li>Packing and retail store distribution</li><li>Cross-docking and trans-loading</li><li>Load building</li><li>Tailor-made service to your requirements</li></ul> "
      ],
    };
    return (
      <>
        <Banner bredcrumb="Wharehousing And Distribution" />
        <Services service={service} />
      </>
    );
}

export default WharehousingAndDistribution