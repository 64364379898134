import React from 'react'
import Banner from './includes/Banner'
import Infos from './includes/Infos';

const DocumentForATAClearance = () => {
    document.title = "Document For ATA Carnet | Easy Global";
    const infos = {
      name: "Document For",
      span: "ATA Carnet",
      paragraphs: [
        "<ul><li>Original Carnet (Document for temporary admission of items).</li><li>Original or authenticated copy of authorization / invitation. </li><li>Full details of item imported with weight of individual item, packing details etc.</li></ul>        "
      ],
    };
  return (
    <>
    <Banner bredcrumb="Document For ATA Carnet" />
    <Infos infos={infos} />
    </>
  )
}

export default DocumentForATAClearance