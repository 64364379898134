import {
  IconArrowForward,
  IconBuildingWarehouse,
  IconCertificate,
  IconChalkboard,
  IconFileImport,
  IconForklift,
  IconPlaneInflight,
  IconShip,
  IconTruckDelivery,
} from "@tabler/icons-react";
import React, {useState} from "react";
import contact from "../../assets/images/contact.svg";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/1..jpg";
import img2 from "../../assets/images/2..jpg";
import img3 from "../../assets/images/3 (1).jpg";
import img4 from "../../assets/images/4 (1).jpg";
import img5 from "../../assets/images/5.jpg";
import img6 from "../../assets/images/6.jpg";
import img7 from "../../assets/images/7.jpg";
import img8 from "../../assets/images/8.jpg";
import veedol from "../../assets/images/logos/veedol.png";
import thermax from "../../assets/images/logos/thermax.svg";
import forbes from "../../assets/images/logos/forbes-marshall.png";
import buhler from "../../assets/images/logos/buhler.png";
import unominda from "../../assets/images/logos/unominda.png";
import gulshan from "../../assets/images/logos/gulshan.png";
import gandhar from "../../assets/images/logos/GANDHAR-White-LOGO.png";
import tata from "../../assets/images/logos/tata-chemicals.png";
import ibl from "../../assets/images/logos/ibl.png";
import ocean from "../../assets/images/ocean-Freight.png"
import air from "../../assets/images/air-Freight.png"
import cc from "../../assets/images/custom-clearance.png"
import inland from "../../assets/images/inland.png"
import chartering from "../../assets/images/chartering.png"
import warehouse from "../../assets/images/warehouse.png"
import global from "../../assets/images/global.png"
import export1 from "../../assets/images/export.png"

const Home = () => {
  document.title = "Home | Easy Global";
  const genralInformations = [
    {
      id: 1,
      name: "Document For Import Clearance",
      route: "document-for-import-clearance",
    },
    {
      id: 2,
      name: "Document For Export Clearance",
      route: "document-for-export-clearance",
    },
    {
      id: 3,
      name: "Document For ATA Carnet",
      route: "document-for-export-clearance",
    },
    {
      id: 4,
      name: "Mesurement Criteria",
      route: "mesurement-criteria",
    },
    {
      id: 5,
      name: "Weight Criteria",
      route: "weight-criteria",
    },
  ];

  const services = [
    {
      id: 1,
      name: "Ocean Freight",
      route: "/ocean-Freight",
      icon: <IconShip width={40} height={40} />,
      img: ocean
    },
    {
      id: 2,
      name: "Air Freight",
      route: "/air-Freight",
      icon: <IconPlaneInflight width={40} height={40} />,
      img: air
    },
    {
      id: 3,
      name: "Custom Clearance",
      route: "/custom-clearance",
      icon: <IconChalkboard width={40} height={40} />,
      img: cc
    },
    {
      id: 4,
      name: "Inland Tranportation",
      route: "/inland-transportation",
      icon: <IconTruckDelivery width={40} height={40} />,
      img: inland
    },
    {
      id: 5,
      name: "Chartering",
      route: "/chartering",
      icon: <IconForklift width={40} height={40} />,
      img: chartering
    },
    {
      id: 6,
      name: "Warehousing and distribution",
      route: "/wharehousing-and-distribution",
      icon: <IconBuildingWarehouse width={40} height={40} />,
      img: warehouse
    },
    {
      id: 7,
      name: "Global Freight Forwarding",
      route: "/global-Freight-forwarding",
      icon: <IconArrowForward width={40} height={40} />,
      img: global
    },
    {
      id: 8,
      name: "Export / Import Consultancy",
      route: "/export-import-consultancy",
      icon: <IconFileImport width={40} height={40} />,
      img: export1
    },
  ];

  const clients = [
    { img: veedol },
    { img: forbes },
    { img: gulshan },
    { img: buhler },
    { img: thermax },
    { img: unominda },
    { img: gandhar },
    { img: tata },
    { img: ibl },
  ];


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct WhatsApp link with form data
    const whatsappLink = `https://wa.me/+919935990372?text=Name: ${formData.name}%0AEmail: ${formData.email}%0APhone: ${formData.phone}%0AMessage: ${formData.message}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="true"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="5"
            aria-label="Slide 6"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="6"
            aria-label="Slide 7"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="7"
            aria-label="Slide 8"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={img1} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img2} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img3} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img4} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img5} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img6} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img7} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img8} class="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div className="about">
        <div className="text-center container my-5">
          <h1 className="">
            About <span>Us</span>
          </h1>
          <p className="w-75 mx-auto">
            Easy Global. a name to reckon with in service excellence renders
            Supply Chain logistics solutions from one end to other end of the
            Supply Chain Process. Easy Global. established in 2014, offering
            point-to-point global logistics solution across the world. Today, we
            cover the entire spectrum of Logistic solutions ranging air, sea &
            land movements with expertise in custom clearance & own fleets of
            land transport. Easy Global logistics employs a multi-cultural team
            of professionals to deliver real time service to it's esteemed
            customers.
          </p>
          <Link to="/about-us" className="primary-button">
            Read More
          </Link>
        </div>
      </div>
      <div className="services">
        <div className="container py-5">
          <h1 className="text-center">
            Our <span>Services</span>
          </h1>
          <div className="row py-5 gap-5 justify-content-center">
            {services.map((service) => (
              <div
                className="col-md-3 text-center bg-white col-lg-3 col-6 d-flex align-items-center justify-content-center flex-column gap-3 shadow rounded p-3"
                key={service.id}
              >
                <img src={service.img} className="w-100 rounded" alt="" />
                <h6>{service.name}</h6>
                <Link className="primary-button" to={service.route}>
                  Know More
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="client py-5">
        <div className="container">
          <h1 className="text-center">
            Our <span>Major Clients</span>
          </h1>
          
          <div className="row gap-3 justify-content-center py-5 align-items-center">
            {clients.map((client) => (
              <div className="col-3 col-md-1 col-lg-1">
                <img src={client.img} className="w-100" alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="genral-information">
        <div className="container py-5">
          <h1 className="text-center text-light">
            Genral <span className="text-light">Information</span>
          </h1>
          <div className="row py-5 gap-4 justify-content-center">
            {genralInformations.map((info) => (
              <div className="col-md-3 text-center gap-3 shadow bg-white rounded d-flex justify-content-center align-item-center flex-column p-3">
                <div className="icon mx-auto">
                  <IconCertificate />
                </div>
                <h5>{info.name}</h5>
                <div className="d-flex justify-content-center">
                  <Link className="primary-button" to={info.route}>
                    Know More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="container py-5">
          <h1 className="text-center">
            Contact <span>Us</span>
          </h1>
          <div className="row py-5">
            <div className="col-md-6 col-10 align-self-center">
              <img src={contact} className="w-100" />
            </div>
            <div className="col-md-6">
              <form action="" onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label htmlFor="">
                    Name <span>*</span>
                  </label>
                  <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} />
                </div>
                <div className="mb-2">
                  <label htmlFor="">
                    Email <span>*</span>
                  </label>
                  <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control" />
                </div>
                <div className="mb-2">
                  <label htmlFor="">
                    Phone <span>*</span>
                  </label>
                  <input type="tel" name="phone" value={formData.phone} onChange={handleChange} className="form-control" />
                </div>
                <div className="mb-2">
                  <label htmlFor="">
                    Message <span>*</span>
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control"
                    id=""
                    cols="30"
                    rows="5"
                  ></textarea>
                </div>
                <div className="mb-2">
                  <input
                    type="submit"
                    className="form-control primary-button"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
