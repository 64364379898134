import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const InlandTransportation = () => {
    document.title = "Inland Transportation | Easy Global";
  const service = {
    name: "Inland",
    span: "Transportation",
    paragraphs: [
      "<b>Easy Global</b>. has its own fleet of Trailers, Mini Trucks, Cargo Vans etc. to cater to the demands of Door-to-Door Delivery for our valued customers. Transportation is one of the vital part of modern logistics segment and its cycles begin with pick-up of cargo / consignments to the designated port, custom station and/or warehouses."
    ],
  };
  return (
    <>
    <Banner bredcrumb="Inland Transportation" />
    <Services service={service} />
  </>
  )
}

export default InlandTransportation