import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const OceanFreight = () => {
  document.title = "Ocean Freight | Easy Global";
  const service = {
    name: "Ocean",
    span: "Freight",
    paragraphs: [
        "<b>Easy Global</b> team consist of highly specialized professionals from the different industry and we have the capability to provide a reliable and cost effective FCL (Full Container Load), LCL (Less than Container Load) and Break Bulk Services with multiple carrier options. Our Sea Freight experts are trained on the latest cargo handling and security requirements and are committed to assist our customer at all times. We have a dedicated customer care center that caters to our esteemed clients and fulfills the customer requirements and queries.", 
        "We also have a live 24 hour tracking facility available so that the freight is constantly tracked and reported and our end client feels relieved. Through, our SAP system, our team ensures that all business decisions are free of any form of bias, duplication or redundancy. Therefore, every effort is carved out of operational productivity.",
        "Not only this, we have strategic partnerships with Ocean freight carriers that basically underline the myriad portfolio of services we have to offer with credibility."
    ]
  }
  return (
    <>
      <Banner bredcrumb="Ocean Freight" />
      <Services service={service} />
    </>
  );
};

export default OceanFreight;
