import { Routes, Route, BrowserRouter } from "react-router-dom";

import Navbar from "./Components/Layouts/Navbar";
import Home from "./Components/Pages/Home";
import Footer from "./Components/Layouts/Footer";
import About from "./Components/Pages/About";
import Oceanfreight from "./Components/Pages/OceanFrieght";
import Airfreight from "./Components/Pages/AirFrieght";
import CustomClearance from "./Components/Pages/CustomClearance";
import InlandTransportation from "./Components/Pages/InlandTransportation";
import Chartering from "./Components/Pages/Chartering";
import WharehousingAndDistribution from "./Components/Pages/WharehousingAndDistribution";
import GlobalfreightForwarding from "./Components/Pages/GlobalFrieghtForwarding";
import ImportExportConsultancy from "./Components/Pages/ImportExportConsultancy";
import DocumentForImportClearance from "./Components/Pages/DocumentForImportClearance";
import DocumentForExportClearance from "./Components/Pages/DocumentForExportClearance";
import DocumentForATAClearance from "./Components/Pages/DocumentForATAClearance";
import Mesurement from "./Components/Pages/Mesurement";
import Weight from "./Components/Pages/Weight";
import Contact from "./Components/Pages/Contact";
import OceanFreight from "./Components/Pages/OceanFrieght";
import AirFreight from "./Components/Pages/AirFrieght";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route
            path="/about-us"
            element={<About />}
          ></Route>
          <Route
            path="/ocean-freight"
            element={<OceanFreight />}
          ></Route>
          <Route
            path="/air-freight"
            element={<AirFreight />}
          ></Route>
          <Route
            path="/custom-clearance"
            element={<CustomClearance />}
          ></Route>
          <Route
            path="/inland-transportation"
            element={<InlandTransportation />}
          ></Route>
          <Route
            path="/chartering"
            element={<Chartering />}
          ></Route>
          <Route
            path="/wharehousing-and-distribution"
            element={<WharehousingAndDistribution />}
          ></Route>
          <Route
            path="/global-freight-forwarding"
            element={<GlobalfreightForwarding />}
          ></Route>
          <Route
            path="/export-import-consultancy"
            element={<ImportExportConsultancy />}
          ></Route>
          <Route
            path="/document-for-import-clearance"
            element={<DocumentForImportClearance />}
          ></Route>
          <Route
            path="/document-for-export-clearance"
            element={<DocumentForExportClearance />}
          ></Route>
          <Route
            path="/document-for-ata-carnet"
            element={<DocumentForATAClearance />}
          ></Route>
          <Route
            path="/mesurement-criteria"
            element={<Mesurement />}
          ></Route>
          <Route
            path="/weight-criteria"
            element={<Weight />}
          ></Route>
          <Route
            path="/contact-us"
            element={<Contact />}
          ></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
