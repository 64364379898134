import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const AirFreight = () => {
  document.title = "Air Freight | Easy Global";
  const service = {
    name: "Air",
    span: "Freight",
    paragraphs: [
      "We handle air consignments on port to port & Door to Door basis with full efficiency, we have contracted rates & service contracts with many global airlines to customer & efficiently managing the supply chain. We do both air Import & Exports.",
      "Air Import : we are considered as experts of Imports forwarding industry, due to our strong relations with ca"
    ],
  };
  return (
    <>
      <Banner bredcrumb="Air Freight" />
      <Services service={service} />
    </>
  );
};

export default AirFreight;
