import React from "react";
import { Link } from "react-router-dom";

const Services = ({ service }) => {
  return (
    <div className="my-5 container service">
      <div className="row gap-3">
        <div className="col-md-3 gap-1 text-center d-flex flex-column">
          <Link className="primary-button" to="/ocean-freight">
            Ocean freight
          </Link>
          <Link className="primary-button" to="/air-freight">
            Air freight
          </Link>
          <Link className="primary-button" to="/custom-clearance">
            Custom Clearance
          </Link>
          <Link className="primary-button" to="/inland-transportation">
            Inland Transportation
          </Link>
          <Link className="primary-button" to="/chartering">
            Chartering
          </Link>
          <Link className="primary-button" to="/wharehousing-and-distribution">
            Wharehousing and Distribution
          </Link>
          <Link className="primary-button" to="/global-freight-forwarding">
            Global freight Forwarding
          </Link>
          <Link className="primary-button" to="/export-import-consultancy">
            Import / Export Consultancy
          </Link>
        </div>
        <div className="col-md-8">
          <h2>
            {service.name} <span>{service.span}</span>
          </h2>
          {service.paragraphs.map((paragraph, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
