import React from "react";
import Banner from "./includes/Banner";

const About = () => {

  document.title = "About Us | Easy Global"
  return (
    <>
      <Banner bredcrumb="About Us" />

      <div className="container py-5">
        <h2>
          About <span>Us</span>
        </h2>
        <p>
          Easy Global. a name to reckon with in service
          excellence renders Supply Chain logistics solutions from one end to
          other end of the Supply Chain Process. Easy Global.
          established in 2014, offering point-to-point global logistics solution
          across the world. Today, we cover the entire spectrum of Logistic
          solutions ranging air, sea & land movements with expertise in custom
          clearance & own fleets of land transport. Easy Global employs
          a multi-cultural team of professionals to deliver real time service to
          it's esteemed customers.
        </p>
      </div>
      <div className="container py-4">
        <h2>
          Our <span>Mission</span>
        </h2>
        <p>
        To be the first choice for Logistics related services, committed to deliver best/desired results to our valued patrons / customers by shortening the overall transaction cycle and minimizing logistics costs wherever possible in the larger interest of our clientele.
        </p>
      </div>
      <div className="container py-4 mb-5">
        <h2>
          Our <span>Vision</span>
        </h2>
        <p>
        To become an integral part of our clients' plans and process and to fulfill their global sourcing and distribution needs.
        </p>
      </div>
    </>
  );
};

export default About;
