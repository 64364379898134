import React from 'react'
import Banner from './includes/Banner'
import Infos from './includes/Infos';

const DocumentForExportClearance = () => {
    document.title = "Document For Export Clearance | Easy Global";
    const infos = {
      name: "Document For",
      span: "Export Clearance",
      paragraphs: [
        "<ul><li>Commercial Invoice & Packing list.</li><li>Copy of self attested LC / Purchase order / Contract.</li><li>Mandatory Certificates/ Authorizations from Govt. Agency(s) like Test Certificates if required at the pre-shipment level.</li><li>Form SDF duly signed by the exporter.</li><li>Annexure-1 (Drawback Declaration) + Value declaration.</li></ul>        "
      ],
    };
  return (
    <>
    <Banner bredcrumb="Document For Export Clearance" />
    <Infos infos={infos} />
    </>
  )
}

export default DocumentForExportClearance