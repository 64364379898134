import {
  IconArrowRight,
  IconBrandFacebook,
  IconBrandFacebookFilled,
  IconBrandInstagram,
  IconBrandWhatsapp,
  IconBrandYoutube,
  IconMail,
  IconMapPin,
  IconPhone,
  IconRegistered,
} from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

const Footer = () => {
  return (
    <>
       <div className="footer">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-3">
            <img src={Logo} className="bg-white p-3 rounded w-100" alt="" />

            <div className="d-flex py-5 justify-content-center gap-3">
              <a href="https://www.facebook.com/Easygloballogistic/" target="_blank"><IconBrandFacebook width={30} /></a>
              <a href="https://www.instagram.com/easygloballogistics" target="_blank"><IconBrandInstagram width={30} /></a>
              <a href="www.youtube.com/@EasyGlobalLogistics"><IconBrandYoutube width={30} /></a>
              <a href="https://wa.me/+919935990372" target="_blank"><IconBrandWhatsapp width={30} /></a>
            </div>
            <div className="d-flex flex-column text-center py-5 justify-content-center gap-3">
              <a href="mailto:sales@easygloballogistics.com" target="_blank"><IconMail width={15} /> sales@easygloballogistics.com</a>
              <a href="mailto:info@easygloballogistics.com" target="_blank"><IconMail width={15} /> info@easygloballogistics.com</a>
            </div>
          </div>
          <div className="col-md-3">
            <h3>
              <span className="text-light">Useful Links</span>
            </h3>
            <li>
              <Link to="/" onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )} >
                <IconArrowRight /> Home
              </Link>
            </li>
            <li>
              <Link to="about-us" onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> About
              </Link>
            </li>
            <li>
              <Link to="contact-us" onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Contact
              </Link>
            </li>
            <li>
              <Link to="document-for-import-clearance" onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Document For Import Clearance
              </Link>
            </li>
            <li>
              <Link to="document-for-export-clearance" onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Document For Export Clearance
              </Link>
            </li>
            <li>
              <Link to="document-for-ata-carnet"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Document For ATA Carnet
              </Link>
            </li>
            <li>
              <Link to="mesurement-criteria"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Mesurement Criteria
              </Link>
            </li>
            <li>
              <Link to="weight-criteria"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Weight Criteria
              </Link>
            </li>
          </div>
          <div className="col-md-3">
            <h3>
              <span className="text-light">Services</span>
            </h3>
            <li>
              <Link to="ocean-Freight"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Ocean Freight
              </Link>
            </li>
            <li>
              <Link to="air-Freight"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Air Freight
              </Link>
            </li>
            <li>
              <Link to="custom-clearance"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Custom Clerance
              </Link>
            </li>
            <li>
              <Link to="inland-transportation"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Inland Transportation
              </Link>
            </li>
            <li>
              <Link to="chartering"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Chartering
              </Link>
            </li>
            <li>
              <Link to="wharehousing-and-distribution"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Wharehousing And Distribution
              </Link>
            </li>
            <li>
              <Link to="global-Freight-forwarding"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Global Freight Forwarding
              </Link>
            </li>
            <li>
              <Link to="export-import-consultancy"  onClick={()=>(
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              )}>
                <IconArrowRight /> Export - Import Consultancy
              </Link>
            </li>
          </div>
          <div className="col-md-3">
            <h3>
              <span className="text-light">Contact</span>
            </h3>
            <li>
              <IconMapPin className="text-light" />{" "}
              <span className="text-light">
                Address: Samardheera Road, Ground-122 Bechu Bhawan, NH-24,
                Nautanwa Raniya Pur, Maharajganj, Uttar Pradesh-273162
              </span>{" "}
            </li>

            <li>
              <IconRegistered className="text-light" />{" "}
              <span className="text-light">GSTIN No.-09AYGPP7302Q2ZQ</span>
            </li>
            <li>
              <IconPhone className="text-light" />{" "}
              <span className="text-light">
                 +91-9628249799 (India)
              </span>
            </li>
            <li>
              <IconPhone className="text-light" />{" "}
              <span className="text-light">
              +977-9808279305 (Nepal)
              </span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright bg-white">
        <p className="py-3 mb-0 text-center shadow">&copy; Design By <a href="https://juneco.in" target="_blank">Juneco Infotech Pvt Ltd</a></p>
    </div>
    </>
  );
};

export default Footer;
