import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const GlobalFreightForwarding = () => {
    document.title = "Global Freight Forwarding | Easy Global";
    const service = {
      name: "Global Freight ",
      span: "Forwarding",
      paragraphs: [
        "Easy Global. , is a well established name in international Global Freight Forwarding industry and proudly offer the most competitive rates, reliable and value oriented services and always open to add value to your business.",
        "We have permanent business associates in all major overseas destinations like USA, Europe, China, Korea, Singapore, Malaysia, Saudi Arabia and other countries and therefore, we can offer to our valued customers a very effective logistics chain."
      ],
    };
    return (
      <>
        <Banner bredcrumb="Global Freight Forwarding" />
        <Services service={service} />
      </>
    );
}

export default GlobalFreightForwarding