import React from "react";
import Banner from "./includes/Banner";
import Services from "./includes/Services";

const ImportExportConsultancy = () => {
  document.title = "Export Import Consultancy | Easy Global";
  const service = {
    name: "Export Import",
    span: " Consultancy",
    paragraphs: [
      "For the convenience of our valued and regular customers, we undertake the following types of liaisoning /consultancy jobs:",
      "<ul><li>Arranging Importer-Exporter Code(IEC)</li> <li>Advance Authorisation / DEPB Scrips from concerned DGFT / JDGFT offices.</li><li>Consultancy for Appeal against Custom Adjudication / order before the Appellate Authorities.</li><li>Getting Brand Rate of Duty Drawback fixed</li><li>Handling Duty Drawback cases at Customs u/s 74 and 75 of Custom Act, 1962.</li></ul>",
    ],
  };
  return (
    <>
      <Banner bredcrumb="Export Import Consultancy" />
      <Services service={service} />
    </>
  );
};

export default ImportExportConsultancy;
